import moengageEventConstants from "@services/moengage/moengageEventConstants";
import moengageAttributesConstants from "@services/moengage/moengageAttributesConstants";

const setUserData = async ({auth}) => {
    await window.Moengage.add_unique_user_id(auth?.id).then(async () => {
        updateUserData({auth: auth});
    })
}
const updateUserData = ({auth}) => {
    window.Moengage.add_user_name(auth?.name);
    window.Moengage.add_email(auth?.email);
    window.Moengage.add_mobile(auth?.mobile);
    setUserAttribute({
        attributeName: moengageAttributesConstants.USER_ID,
        attributeValue: auth?.userid
    });
    setUserAttribute({
        attributeName: moengageAttributesConstants.KYC_STATUS,
        attributeValue: auth?.kycStatus
    });
}
const setUserAttribute = ({attributeName, attributeValue}) => {
    window.Moengage.add_user_attribute(attributeName, attributeValue);
}
const logoutUser = () => {
    logEvent({eventName: moengageEventConstants.LOGOUT_SUCCESS,});
    window.Moengage.destroy_session();
}
const updateUserID = ({investorId}) => {
    window.Moengage.update_unique_user_id(investorId);
}

const logEvent = ({eventName, parameters = {}}) => {
    window.Moengage.track_event(eventName, parameters);
}

module.exports = {
    setUserData,
    updateUserData,
    setUserAttribute,
    logoutUser,
    updateUserID,
    logEvent
};