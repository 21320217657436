module.exports = {
    OPPORTUNITY_ID: "opportunity_id",
    DEMAT_ACCOUNT_TYPE: "demat_account_type",
    ASSETS_INVESTING_IN: "assets_investing_in",
    PORTFOLIO_SIZE: "portfolio_size",
    IS_ONBOARDING_DOCUMENT: "is_onboarding_document",
    OPPORTUNITY_SLUG: "opportunity_slug",
    COUNTER_PARTY: "counter_party",
    ASSET_CLASS: "asset_class",
    IRR: "irr",
    TENURE: "tenure",
    INVESTED_AMOUNT: "invested_amount",
    FORM_ID: "form_id",
    SURVEY_LINK_ID: "survey_link_id",
    STATUS_CODE: "status_code",
    PATH: "path",
    IS_FOR_LOGGED_IN_ONLY: "is_for_logged_in_only",
    PRODUCT_SLUG: "product_slug",
    BLOG_SLUG: "blog_slug",
    PAYMENT_GATEWAY: "payment_gateway",
    INVESTMENT_TIME_FRAME: "investment_time_frame",
    IS_KYC_FLOW: "is_kyc_flow",
};
